import React from "react"

import { Heading, Text, Box } from "grommet"
import { graphql } from "gatsby"
import Anchor from "../../../components/common/Anchor"
import PageLayout from "../../../components/Layouts/PageLayout"

export const query = graphql`
  query($locale: String!) {
    strapiArticleIndex(locale: { eq: $locale }) {
      path_prefix
    }
    strapiCategoryIndex(locale: { eq: $locale }) {
      id
      sub_heading
      title
      path_prefix
      all_articles_link {
        href
        label
      }
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    allStrapiCategory(
      filter: { isDraft: { eq: false }, locale: { eq: $locale } }
    ) {
      nodes {
        id
        articles {
          content
          description
          updated_at
          published_at
          title
          slug
          image {
            id
          }
        }
        name
        slug
        updated_at
        published_at
      }
    }
  }
`

const AllCategoryPage = ({
  data: {
    allStrapiCategory: { nodes },
    strapiCategoryIndex: {
      sub_heading,
      title,
      all_articles_link,
      path_prefix: category_path_prefix,
    },
    strapiArticleIndex: { path_prefix: article_path_prefix },
  },
}) => {
  return (
    <PageLayout>
      <PageLayout.Heading heading={title} sub_heading={sub_heading} />
      <PageLayout.Content>
        <Anchor href={`/${article_path_prefix}/`}>
          <Text>{all_articles_link.label}</Text>
        </Anchor>
        {nodes.map(node => (
          <Anchor href={`/${category_path_prefix}/${node.slug}/`}>
            <Heading level={2}>{node.name}</Heading>
          </Anchor>
        ))}
      </PageLayout.Content>
    </PageLayout>
  )
}

AllCategoryPage.propTypes = {}

export default AllCategoryPage
